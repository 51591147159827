import React from 'react';
import PropTypes from 'prop-types';

export default class CareerApplicationFilterForm extends React.PureComponent {

    static propTypes = {
        role: PropTypes.string.isRequired,
        roles: PropTypes.arrayOf(PropTypes.string).isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            role: props.role
        };
    }

    updateRole = (event) => {
        this.setState({role: event.target.value});
    }

    setActiveFilter(e) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set('showing_archived', e.target.checked);
        window.location.search = searchParams.toString();
    }

    render() {
        const { role } = this.state;

        const searchParams = new URLSearchParams(window.location.search);
        const showingArchived = searchParams.get('showing_archived') === 'true';

        const searchParamsWithRole = new URLSearchParams(window.location.search);
        searchParamsWithRole.set('role', role);

        return (
            <div style={{display: 'flex', columnGap: '6px', alignItems: 'center'}}>
                <label>
                    <input type="checkbox" onChange={this.setActiveFilter} checked={showingArchived}/>
                    Show archived
                </label>

                <select value={role} onChange={this.updateRole}>
                    <option value="">All Roles</option>

                    {
                        this.props.roles.map((role) =>
                            <option key={role} value={role}>{role}</option>
                        )
                    }
                </select>

                <a href={`/career_applications?${searchParamsWithRole.toString()}`} className="btn btn-sm btn-primary">
                    Filter
                </a>

                {
                    role &&
                    <a href={`/career_applications/review?role=${role}`} className="btn btn-sm btn-primary">
                        Start Review
                    </a>
                }
            </div>
        );
    }

}
